html {
  min-height: 100%;
  position: relative;
}
body {
  font-family: $font-stack;
  color: $primary-text;
  font-size: $f-size-m;
  line-height: $b-line-height;
  margin-bottom: 50px;
}

a, a:visited {
  color: $c-button;
}

a:hover {
  color: $primary-text;
}

p {
  padding: $gap/2 0;
}

.page-title {
  background: url('../../images/border-dash.png') repeat-x left bottom;
  margin-bottom: 0.7em;
  h2 {
    display: inline-block;
    padding: 5px 0 10px;
    margin: 0;
    font-style: italic;
    a {
      color: $primary-text;
    }
    span {
      font-style: normal;
    }
  }
}

.divider {
  background: url('../../images/border-dash.png') repeat-x left top;
  height: 2px;
  margin: $gap 0;
}

.blue {
  color: $c-button;
}

.green {
  color: $c-success;
}

.red, .panel-list .panel-body td i.material-icons.red {
  color: $c-danger;
}

.yellow {
  color: $c-action;
}