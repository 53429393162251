//TODO Clean this up a bit
.table-dashboard {
  thead {
    background: $c-button;
    color: #fff;
    tr {
      th {
        font-weight: 100;
        border-bottom: none;
        font-size: $f-size-s;
        &:first-of-type {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        &:last-of-type {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
        a {
          color: #fff;
          font-size: $f-size-s;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        vertical-align: middle;
        font-size: 14px;
        i {
          vertical-align: -5px;
          line-height: 16px;
        }
        &:first-of-type {
          width: 5%;
        }
        &:nth-of-type(2) {
          text-align: center;
          width: 15%;
        }
        &:nth-of-type(3) {
          width: 50%;
        }
        &:last-of-type {
          width: 30%;
        }
      }
    }
  }
}

.dashboard-sidebar {
  .row {
    margin: 0 0 $gap 0;
    text-align: center;
    display: table;
    width: 100%;
  }
  .col-md-6 {
    color: #fff;
    padding: $gap*2 $gap;
    display: table-cell;
    float: none;
    width: 50%;
    vertical-align: middle;
    &:first-of-type {
      background: lighten($c-secondary, 4%);
      font-size: 42px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &.rush:first-of-type {
      background: lighten($c-action, 4%);
    }
    &.hold:first-of-type {
      background: lighten($c-danger, 4%);
    }
    &:last-of-type {
      background: $c-secondary;
      font-size: $f-size-l;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    &.rush:last-of-type {
      background: $c-action;
    }
    &.hold:last-of-type {
      background: $c-danger;
    }
  }
    a:hover, a:visited {
        text-decoration: none;
        color:inherit;
    }
}

.fixed-action-btn {
  position: fixed;
  right: 23px;
  bottom: 23px;
  margin-bottom: 0;
  z-index: 998;
  .btn-floating {
    display: inline-block;
    color: #fff;
    position: relative;
    overflow: hidden;
    z-index: 1;
    width: 37px;
    height: 37px;
    line-height: 37px;
    padding: 0;
    background-color: $c-action;
    border-radius: 50%;
    cursor: pointer;
    vertical-align: middle;
    transition: all .2s ease-in-out;
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, .25);
    i {
      width: inherit;
      display: inline-block;
      text-align: center;
      color: #fff;
      line-height: 37px;
    }
    &:before {
      border-radius: 0;
    }
    &.btn-large {
      width: 56px;
      height: 56px;
      i {
        line-height: 56px;
      }
    }
  }
  .btn-floating.btn-large:hover {
    background-color: darken($c-action, 10%);
  }
  &.active .btn-floating.btn-large {
    background-color: darken($c-action, 10%);
    transform: rotate(45deg);
    transform-origin: center center;
    border: none;
  }
  &.btn-floating-single.active .btn-floating.btn-large {
    transform: rotate(0deg);
  }
  &.active ul {
    visibility: visible;
  }
  ul {
    right: 10px;
    width: 240px;
    text-align: center;
    position: absolute;
    bottom: 64px;
    margin: 0;
    visibility: hidden;
    padding-left: 0;
    li {
      margin-bottom: 15px;
      list-style-type: none;
      float: right;
      &:hover .label-floating {
        opacity: 100;
        background: $primary-text;
        color: #fff;
      }
    }
    .label-floating, a.btn-floating {
      opacity: 0;
    }
    a.btn-floating {
      background: $secondary-text;
    }
    .label-floating {
      width: auto;
      font-size: $f-size-s;
      text-align: right;
      border-radius: 4px;
      padding: 0 10px;
      opacity: 0;
      transition: all .2s ease-in-out;
      display: inline-block;
      margin-right: 6px;
    }
  }
}
.label-floating {
  width: auto;
  font-size: $f-size-s;
  text-align: right;
  border-radius: 4px;
  padding: 0 10px;
  opacity: 0;
  transition: opacity .2s ease-in-out;
  display: inline-block;
  margin-right: 6px;
}

.fixed-action-btn.btn-floating-single:hover .label-floating, .btn-floating li:hover .label-floating {
  opacity: 100;
  background: $primary-text;
  color: #fff;
}

.fixed-action-btn.btn-floating-single .label-floating {
  position: absolute;
  right: -999px;
}

.fixed-action-btn.btn-floating-single:hover .label-floating {
  position: absolute;
  top: 18px;
  right: 68px;
  white-space: nowrap;
}